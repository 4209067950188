.policy-title {
	display: flex;
	align-items: center;

	h2 {
		color: var(--color-primary);
	}

	span {
		float: left;
		margin-right: 1rem; 
	}
}
