
.form-contact-details {
	margin: 2rem 0;

	.field {
		margin-bottom: 0.875rem;
		scroll-margin-top: 5rem;
		@media screen and (min-width: 992px) {
			scroll-margin-top: 1rem;
		}

		&.no-input{
			padding-bottom: 0px;
			margin-bottom: 0px;
		}
		
		.postal-address-mode-button{
			margin-top: 0.875rem;
		}

		.postal-address-mode-button{
			margin-top: 0.875rem;
		}
	}

	select {
		font-size: 1.0625rem;
	}

	.address-mode-button {
		margin-bottom: 1rem;
		background: transparent;
		border: 0;
		padding: 0;
		text-decoration: underline;
	}

	label{
		margin-bottom: 0.5rem;
	}

	input{
		font-size: 1.0625rem;

		&:disabled{
			background: #e5e5e5;
		}
	}

	.field-contact-postal-toggle{
		span{
			margin-left: 4px;
		}
	}

	.terms {
		width: 80%;
		@media screen and (min-width: 992px) {
			width: 536px;
		}
	}

	.help-label{
		display: flex;
		justify-content: space-between;
		align-items: center;
		position:relative;

		.info-icon{
			margin: 0 0.5rem;
		}

		.info-icon:hover ~ .contact-name-tooltip, .contact-name-tooltip:hover {
			transform: scale(1);
		}

		.contact-name-tooltip{
			position:absolute;
			background: #FFF;
			border-radius: 5px;
			padding: 1rem;
			box-shadow: 0 0 5px #333;
			top: 24px;
			transition: all .25s .25s ease-in-out;
			transform-origin: top right;
			transform: scale(0);
			font-size: 0.875rem;
		}
	}

	button.submit {
		display: block;
		margin-bottom: 1rem;
		min-width: 90%;
		@media screen and (min-width: 992px) {
			min-width: 370px;
		}
	}

	a[href^="tel:"]{

		@media screen and (min-width: 992px) {
				color: var(--color-text);
		}
	}
}
