
.page-login {
	width: 100%;
	position: relative;
	display:flex;
	flex-direction: column;

	background-image: var(--login-page-background-image);
	background-repeat: no-repeat;
	background-size: cover;
	background-position: 70%;
	min-height: calc(100vh - 352px);

	.login-background-overlay {
		background-color: var(--color-login-background-tint);
		width: 100%;
		height: 100%;
		position: absolute;
	}

	.layout-container {
		
		.login-box {
			position:relative;
			background-color: #FFFFFF;
			width:100%;
			padding: 1rem 2rem;
			box-sizing: border-box;
			margin-top: 4.5rem;
			margin-bottom: 3.5rem;
			max-width: 384px;
		}
	}

	@media screen and (min-width: 992px) {
		.layout-container {
			width: 100%;

			.login-box {
				width: 45%;
				position: relative;
				padding: 2rem 3rem;
				margin-bottom: 4rem;
				margin-top: 4rem;
				max-width: none;
			}
		}
	}
}
