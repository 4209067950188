
.page-make-payment {

	strong {
		color: #000;
	}

	.amount-container {
		background-color: var(--color-primary-tint);
		padding: 1rem;
	}

	.payment-detail-form {
		margin-bottom: 0;
	
		// Status: error occurred
		form > .alert.alert-danger.ng-star-inserted {
			background-color: var(--color-error-light-red);
			padding: 1rem;
			color: #000;
			&::before {
				content: 'Status:';
				font-weight: bold;
				padding-right: 0.25rem;
			}
		}

		.form-group {
			padding-top: 1rem;
			padding-bottom: 1rem;
		}

		// validation errors
		.invalid-feedback {
			color: red;
			padding-top: 0.5rem;
		}

		// match the designs, remove expiry month/year labels
		label[for="expiryDateMonth"], label[for="expiryDateYear"] {
			display: none;
		}

		// match the designs, don't fill screen width
		.row {
			display: flex;
			.col-6 {
				display: inline-block;
				input {
					width: 9rem;
					margin-right: 1rem;
				}
				#div_dd_month_inalidformat {
					width: 9rem;
				}
			}
		}
		#cvn {
			width: 9rem;
		}

		// replaced the submit button with our own set
		button[type="submit"] {
			display: none;
		}
	}

	.total-outstanding {
		background-color: var(--color-primary-tint);
		display: flex;
		align-items: center;
		justify-content: space-between;
		padding: 1rem;
		font-size: 1.125rem;
		.total-amount {
			font-size: 1.5rem;
			font-weight: bold;
		}
	}

	.terms-container {
		padding-top: 1rem;
		// padding-bottom: 2rem;
	}

	.action-buttons {
		padding-top: 2rem;
		padding-bottom: 2rem;
		.form-btn.btn {
			width: 100%;
		}
		@media screen and (min-width: 992px) {
			display: flex;
			justify-content: space-between;
			.form-btn.btn {
				width: 352px;
			}
		}
	}

	// match the loading spinner designs
	.ngx-spinner-overlay {
		background-color: rgba(112, 112, 112, 0.8) !important;
	}
	.loader-spinner {
		height: 100px;
		width: 100%;
		background-image: var(--spinner);
		background-repeat: no-repeat;
		background-size: 100px 100px;
		background-position: center;
	}
	.loading-text {
		background: white;
		padding: 2rem;
		min-width: 220px;
		top: 45%;
		box-shadow: 0px 3px 6px #00000029;
		p.h1 {
			position: relative;
			font-size: 2rem;
			font-family: var(--font-family-headings);
			color: transparent;
			// override "please wait"
			&::after {
				content: 'Just a moment';
				position: absolute;
				left: 0;
				background-color: white;
				color: var(--color-primary);
				width: 100%;
				height: 100%;
				text-align: center;
			}
		}
		p { 
			text-align: center;
		}
	}
}
