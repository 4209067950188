.document-view-email {
	background: white;
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	z-index: 4;
	overflow: auto;

	p {
		padding-top: 2.5rem;
		margin: 0 auto;
		width: 540px;
	}

	.attachments {
		border: 1px solid #bebebe;
		padding: 1rem;
		margin: 0 auto;
		margin-top: 0.5rem;
		box-sizing: border-box;
		width: 540px;
	}
}

