
.layout-container {
	margin-left: 1rem;
	margin-right: 1rem;
	
	@media (min-width: 992px) {
		max-width: 960px;
		margin: 0 auto;
	}
}
