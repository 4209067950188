:root {
	// Primary Colour
	--color-primary: #f33333;
	--color-primary-hover: #01376666;
	--color-primary-tint: #ffd1d5;

	// Secondary Colour
	--color-secondary: rgb(245, 194, 105);
	--color-secondary-hover: rgba(253, 179, 70, 90);

	// Text Colours
	--color-light-grey: #F4F4F4;
	--color-heading: #000000;
	--color-text: #5E5E5E;
	--color-grey: #707070;

	// Button Colours
	--color-primary-button: var(--color-primary);
	--color-primary-button-hover: var(--color-primary-hover);
	--color-secondary-button: var(--color-secondary);
	--color-secondary-button-hover: var(--color-secondary-hover);
	--color-link-button: var(--color-secondary);
	--color-link-button-hover: var(--color-secondary-hover);
	--color-cancel-button: var(--color-grey);
	--color-cancel-button-hover: #f0f0f0;

	// Flash Message Error / Success 
	--color-error: #C60000;
	--color-error-light-red: #C600001A;
	--color-valid: #069B00;

	--color-modal-backdrop: #707070CC;
	--color-login-background-tint: rgba(230, 230, 230, 0.4);
	--color-footer-background: #2b2b2b;
	--color-footer-text: #D9D9CF;
	--color-footer-link: #D9D9CF;

	// Flash messages
	--color-flash-message-green: #069B0026;
	--color-flash-message-green-text: #000000;
	--color-flash-message-red: #C600001A;
	--color-flash-message-red-text: #000000;

	// Icons
	--header-phone-icon: url('../static/phone-solid.svg');
	--footer-phone-icon: url('../static/phone-solid.svg');
	--header-mobile-menu-open-icon: url('../static/burger-menu.svg');
	--header-mobile-menu-close-icon: url('../static/close-solid.svg');

	// Headings Font
	--font-family-headings: Courier, Arial, Helvetica, sans-serif;

	// Buttons Font
	--font-family-buttons: Courier, Arial, Helvetica, sans-serif;

	// Body Font
	--font-family: Courier, Arial, Helvetica, sans-serif;

}
