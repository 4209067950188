.documents-button {
	position: relative;
	float: right;
	padding-left: 2rem!important;
	padding-right: 3.75rem!important;
	background-color: white;
	font-family: var(--font-family-buttons);
	font-size: 1rem;
	font-weight: normal;
	color: var(--color-primary-button);

	cursor: pointer;
	transition: color 0.25s;

	&:hover {
		text-decoration: underline;
		color: var(--color-primary-button-hover);
		svg g {
			fill: var(--color-primary-button-hover);
		}
	}

	svg {
		position: absolute;
		top: 0.25rem;
		right: 0.75rem;
		height: 2rem;
		width: 2rem;
		g {
			fill: var(--color-primary-button);
			transition: fill 0.25s;
		}
	}
}
