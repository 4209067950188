
.policy-header {
	background-color: var(--color-primary);
	color: #FFFFFF;
	height: 60px;
	@media screen and (min-width: 992px) {
		position: relative;
		z-index: 2;
	}

	div {
		display: flex;
		justify-content: space-between;
		align-items: center;
		height: 100%;
		font-size: 1.125rem;
	}
	
	.policy-number { 
		text-align: right;
	}
}
