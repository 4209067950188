.documents-list {
	.table-wrapper {
		border: 1px solid #CBCBCB;
		padding: 1rem;
		margin-bottom: 2.5rem;
		@media screen and (min-width: 992px) {
			padding-left: 2rem;
			padding-right: 2rem;
		}
	}

	button {
		border: 0;
		background: transparent;
		color: var(--color-link-button);
		text-decoration: underline;
		font-family: inherit;
		font-size: 1rem;
		cursor: pointer;
		text-align: left;
		&:hover {
			color: var(--color-link-button-hover);
		}
		padding: 0;
	}

	th button {
		color: var(--color-text);
		text-decoration: none;
		font-weight: bold;
		&:hover {
			color: var(--color-text);
			text-decoration: none;
		}
		&::after {
			content: url(../../static/sort-column.svg);
			padding-left: 0.25rem;
		}
	}

	table.desktop { 
		display: none;
	}

	@media screen and (min-width: 768px) {
		table.mobile { display: none; }
		table.desktop { display: block; }
	}

	table.desktop {
		width: 100%;
		border-collapse: collapse;
		th {
			text-align: left;
			border-bottom: 1px solid #CBCBCB;
			padding-bottom: 1rem;
			&:last-child {
				padding-left: 0.5rem;
			}
			&:nth-child(1) {
				min-width: 150px;
			}
			&:nth-child(2){
				width: 50%;
			}
			&:nth-child(3){
				width: 50%;
			}
		
		
		}
		td {
			padding-top: 1rem;
			&:last-child {
				padding-left: 0.5rem;
			}
		}
		tr.bordered {			
			border-bottom: 1px solid #CBCBCB;
			td button {
				padding-bottom:0.5rem;
			}
		}
	}

	table.mobile {
		width: 100%;
		table-layout: fixed;
		border-collapse: collapse;
		th {
			text-align: left;
			border-bottom: 1px solid #CBCBCB;
			padding-bottom: 1rem;
			&:first-child {
				width: 50%;
			}
			&:last-child {
				text-align: right;
				padding-left: 0.5rem;
				width: 6rem;
			}				
			
			button {
				font-weight: bold;

			}
		}
		tr.meta {
			border-bottom: 1px dotted #bebebe;
			& td {
				padding-bottom: 0.75rem;
				&:first-child {
					width: 50%;
					text-align: left;
				}
			}
			& td[colspan="2"] {
				// text-align: right;
			}
			&:last-child {
				border-bottom: 0;
			}
		}
		td[colspan="3"] {
			width: 100%;
			button {
				display: block;
				text-align: left;
				width: 100%;
				white-space: nowrap;
				overflow: hidden;
				text-overflow: ellipsis;
			}
		}
		td {
			padding-top: 0.5rem;
			&[colspan="3"] {
				padding-top: 1rem;
			}
			&:last-child, &:nth-last-child(2) {
				text-align: right;
			}
		}
	}
}
