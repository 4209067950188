
// Default CSS variables
@use './style/variables' as *;
@use './style/Forms.scss' as *;
@use './style/Buttons.scss' as *;
@use './style/Icons.scss' as *;

// Default CSS styles

html {
	font-size: 12px;

	@media screen and (min-width: 281px) {
		font-size: 15px;
	}

	@media screen and (min-width: 992px) {
		font-size: 16px;
	}
}

body {
	margin: 0;
}

.app {
	font-family: Helvetica, Arial, sans-serif;
	color: var(--color-text);
}

h1, h2, h3, h4, h5, h6 {
	color: var(--color-heading);
}

h1 {
	font-size: 2.25rem;
	margin-bottom: 1.875rem;
}

h2 {
	font-size: 1.5rem;
}

h3 {
	font-size: 1.25rem;
}

p, li { 
	line-height: 1.5;
}

a {
	color: var(--color-primary);
	&:hover { 
		color: var(--color-primary-hover);
	}

	&[href^="tel:"] {
		white-space: nowrap;
	}

	@media screen and (min-width: 992px) {
		&[href*="tel:"] {
			cursor: default;
			pointer-events: none;
			text-decoration: none;
			font-weight: bold;
			color: var(--color-text);
		}
	}
}

.no-wrap{
	white-space: nowrap;
}
// display: flex;
// 	align-items: center;
// 	position: relative;
// 	padding: 0.4rem .8rem .4rem .65rem;
// 	border-radius: 9999px;
// 	background-color: var(--color-info);
// 	color: white;
// 	font-size: 10px;	
// 	line-height: 10px;
// 	text-align: center;
// 	white-space: nowrap;
// 	margin: 0 12px;	
.pill-notification {
	display: flex;
	align-items: center;
	position: relative;
	padding: 0.4rem .8rem .4rem .65rem;
	border-radius: 9999px;
	background-color: var(--color-info);
	color: white;
	font-size: 12px;	
	line-height: 12px;
	text-align: center;
	white-space: nowrap;
	margin: 0 12px;	
	z-index: 1;	

	& svg {
		width: 12px;
		height: 12px;
		padding: 0 4px 0 0;
	}

	&::after {
		content: '';
		position: absolute;
		left: -50%;
		top: -50%;
		width: 200%;
		height: 200%;
		background: var(--color-info);
		border-radius: 9999px;
		transform: translate(-100%, -100%) scale(0);
		opacity: 0;
		animation: pulse 1.5s infinite;
		z-index: -1; // Ensure the pseudo element appears below the base element
	}

	& span {
		padding-top: 1px;
	}
}

@keyframes pulse {
	0% {
		transform: scale(.1);
		opacity: 0;
	}
	25% {
		opacity: .2;
	}
	100% {
		transform: scale(0.8);
		opacity: 0;		
	}
}


