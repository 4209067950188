.btn {
	color: #FFFFFF;
	font: 18px var(--font-family-buttons, inherit);
	font-weight: bold;
	border: 0;
	padding: 0.75rem 1.25rem;
	cursor: pointer;
}

.btn-link {
	background-color: transparent;
	color: var(--color-primary);
	transition: color 0.25s ease-in-out;
	font-family: inherit;
	font-size: 1rem;
	
	&:hover{
		color: var(--color-primary-button-hover);
	}
}

.btn-primary {
	background-color: var(--color-primary-button);
	transition: 0.25s;
	&:hover {
		background-color: var(--color-primary-button-hover);
	}
}

.btn-secondary {
	background-color: var(--color-secondary-button);
	transition: 0.25s;
	&:hover {
		background-color: var(--color-secondary-button-hover);
	}
}

.btn-rounded {
	border-radius: 4px;
}

.form-btn {
	background-color: var(--color-secondary-button);
	color: #FFFFFF;
	border: none;
	padding: 1rem 2rem;
	margin-bottom:1rem;
	box-sizing: border-box;
	font: 18px var(--font-family-buttons, inherit);
	font-weight: bold;
	font-size: 1.375rem;
	display:flex;
	align-items: center;
	justify-content: center;
	transition: all .25s;
	cursor: pointer;

	.chevron-r {
		transition: all .25s ease-in-out;
	}
	&:disabled {
		opacity: 0.5;
		&:hover {
			background-color: var(--color-secondary-button);
			cursor: unset;
		}
	}
	&:hover {
		background-color: var(--color-secondary-button-hover);

		& > .chevron-r {
			transform: translateX(4px);
		}
	}

	&.cancel {
		background-color: var(--color-cancel-button);
		color: var(--color-text);

		&:hover {
			background-color: var(--color-cancel-button-hover);
		}
	}
}

.address-mode-button {
	cursor: pointer;
	transition: color 0.25s ease-in-out;
	color: var(--color-link-button);
	&:hover {
		color: var(--color-link-button-hover);
	}
}

.modal-link {
	color: var(--color-text);
}
