//Icons
.notification-bell{
	display:inline-block;
	vertical-align: top;
	background-image: url('../static/notification-bell.svg');
	background-repeat: no-repeat;
	width: 21px;
	height: 24px;
	min-width: 21px;
	min-height: 24px;
}

.chevron-r{
	display: inline-block;
	background-image: url('../static/chevron-right-regular-white.svg');
	background-repeat: no-repeat;
	background-position: 50%;
	vertical-align: middle;
	width: 0.5rem;
	height: 1.3125rem;
	background-size: 0.5rem 1.3125rem;
	margin-left:4px;
}

.familylife-icon{
	display: inline-block;
	background-image: url('../static/umbrella-light.svg');
	background-repeat: no-repeat;
	background-position: 50% 35%;
	width: 3.125rem;
    height: 3.125rem;
    min-width: 3.125rem;
    min-height: 3.125rem;
    background-size: 140%;
}

// .funeral-icon{
// 	display: inline-block;
// 	background-image: url('../static/product-funeral-grape.svg');
// 	background-repeat: no-repeat;
// 	background-position: 50% 35%;
// 	width: 3.125rem;
//     height: 3.125rem;
//     min-width: 3.125rem;
//     min-height: 3.125rem;
//     background-size: 150%;
// }

// .income-icon{
// 	display: inline-block;
// 	background-image: url('../static/product-income-grape.svg');
// 	background-repeat: no-repeat;
// 	background-position: 50% 35%;
// 	width: 3.125rem;
//     height: 3.125rem;
//     min-width: 3.125rem;
//     min-height: 3.125rem;
//     background-size: 150%;
// }

.payment-details-icon{
	background-image: url('../static/payment-details.svg');
	background-repeat: no-repeat;
	background-position: 0% 50%;
	background-size: 2.6875rem 2.5rem;
	width: 3.4375rem;
	height: 3.125rem;
	min-width: 3.4375rem;
	min-height: 3.125rem;
}

.contact-details-icon{
	background-image: url('../static/envelope.svg');
	background-repeat: no-repeat;
	background-position: 0% 50%;
	background-size: 2.5rem 1.875rem;
	width: 3.4375rem;
	height: 3.125rem;
	min-width: 3.4375rem;
	min-height: 3.125rem;
}

.make-payment-icon {
	background-image: url('../static/make-a-payment.svg');
	background-repeat: no-repeat;
	background-position: 0% 50%;
	background-size: 3.4375rem 3.125rem;
	width: 3.9375rem;
	height: 3.125rem;
	min-width: 3.9375rem;
	min-height: 3.125rem;
}

// .here-to-help-cta-icon{
// 	display:inline-block;
// 	background-image: url('../static/regular-comments-grape.svg');
// 	background-repeat: no-repeat;
// 	background-position: 0% 100%;
// 	background-size: 2.5rem 2.25rem;
// 	width: 2.5rem;
// 	height: 2.25rem;
// 	min-width: 2.5rem;
// 	min-height: 2.25rem;
// }

.info-icon{
	display:inline-block;
	background-image: url('../static/info-solid.svg');
	background-color: #989898;
	background-repeat: no-repeat;
	background-position: 50%;
	background-size: 9px 9px;
    width: 9px;
    height: 9px;
    min-width: 9px;
    min-height: 9px;
    padding: 4px;
	border-radius: 50%;
}

.padlock-icon{
	background-image: url('../static/lock-solid.svg');
	width: 1.125rem;
	height: 1.125rem;
	background-repeat: no-repeat;
}

.footer-phone-icon{
	display: inline-block;
	background-image: var(--footer-phone-icon);
	width:24px;
	height: 24px;
	background-repeat: no-repeat;
	background-size: 24px 24px;
	margin-right: 0.5rem;
}
