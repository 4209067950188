.policy-detail-header {
	display: flex;	
	justify-content: space-between;
	align-items: center;
	flex-wrap: wrap;
}

.policy-detail {
	.policy-change-text {
	font-size: 1.125rem;
	margin:0;
	a{
		color: var(--color-primary);
		
		@media screen and (min-width: 992px){
			&[href*="tel:"]{
				color: var(--color-text);
			}
		}
	}
}

table{
	table-layout: fixed;
}

	.detail-heading{
	margin-top: 3rem;
		margin-bottom: 14px;
	h3{
		margin: 0;
	}
}
	.policy-details-summary, .payment-details, .life-insured-detail, .policy-detail-table-mobile{
	width:100%;
			font-size: 1.0625rem;
	
	& > tbody > tr {
		& > td{
			border-bottom: 1px #FFFFFF solid;
			
			& > table {
				width:100%;
				
				tr{
					display: block;
					margin-bottom: 16px;
					&:last-child {
						margin-bottom: 0px;
					}

					td{
						display: block;
						&:first-child {
							font-weight: bold;
							@media screen and (min-width: 992px) {
								font-weight: normal;
							}
						}
					}
				}
			}

			@media screen and (min-width: 992px) {
				& > table {
					width:100%;
					
					tr{
						display: table-row;
						td{
							display: table-cell;
							&:first-child{
								width: 80%;
							}
						}
					}
				}
			}
		}

		& > td:first-child {
			background-color: var(--color-table-1);
			color:#FFFFFF;
			width: 30%;
			padding: 1rem 3rem 1rem 1rem;
			border-right: 1px #FFFFFF solid;
			vertical-align: top;
			@media screen and (max-width: 991.999px) {
				min-width: 35%;
			}
		}

		& > td:nth-child(2){
			background-color: var(--color-table-3);
			padding: 0.5rem 1rem;
		}
	}    
}

.policy-detail-table {
	display:none;

	@media screen and (min-width: 992px) {
		display: table;
	}

	&.income-protection{
		td:nth-child(4){ // Waiting period
			width: auto;
			white-space: nowrap;
		}
	}
}

.policy-detail-table-mobile{
	display: table;
	margin-top: 1rem;
	@media screen and (min-width: 992px) {
		display: none;
	}
}

table.show-on-mobile {
	display: table;
}

.payment-detail-header {
	display: flex;
	justify-content: space-between;
	align-items: center;
	margin-top: 3rem;
	margin-bottom: 14px;

	h4 {
		margin:0;
	}

	.edit-link-container {
		display: flex;
		align-items: center;

		
		a {
			color: var(--color-link-button);
			transition: color .25s;

			svg{
				width: 1rem;
				height: 1rem;
				margin-right:8px;
				.svg-icon-fill{
					transition: fill .25s;
					fill: var(--color-link-button);
				}
			}

			&:hover {
				color: var(--color-link-button-hover);
				svg{				
					.svg-icon-fill{
						transition: fill .25s;
						fill: var(--color-link-button-hover);
					}
				}
			}
		}
	}
}

.funeral-tl-insured-detail, .life-insured-detail {
	& > tbody > tr:first-child td:nth-child(2) {
		background-color: var(--color-table-2);
	}
}

@media screen and (min-width: 992px) {
	.life-insured {
		margin-bottom: 1rem;
		table table td {
			line-height: 2;
			&:last-child {
				text-align: right;
			}
		}
	}
}

.policy-detail-table {
	width: 100%;
	margin-top: 1rem;

	th {
		background-color: var(--color-table-1);
		font-weight: normal;
		text-align: left;
		color: #FFF;
		@media screen and (min-width: 992px) {
			&:first-child {
				width: 30%;
			}
		}
	}

	th, td {	
		padding: 1rem;
		border-bottom: 1px #FFFFFF solid;
		border-right: 1px #FFFFFF solid;


		&:first-child {
			padding: 1rem 3rem 1rem 1rem;
		}
	}

	td {
		background-color: var(--color-table-3);
		&:first-child {
			background-color: var(--color-table-2);
		}
	}
}

	

	.policy-details-summary tr.policy-owner-name > td:nth-child(2) {
			padding: 0px;
			& > div {
					text-transform: capitalize;
					overflow-x: auto;
					scrollbar-width: thin;
					padding: 0.5rem 1rem;
			}
	}

	.life-insured
	.life-insured-detail-name{
			td:nth-child(2){
					padding: 0px;
					& > div {
							text-transform: capitalize;
							overflow-x: auto;
							scrollbar-width: thin;
							padding: 0.5rem 1rem;
					}
			}
	}
}
