
.form-select-payments {

	.select-payments-table {
		border: 1px solid #cbcbcb;
		margin-bottom: 2rem;
		padding-left: 1rem;
		padding-right: 1rem;
		font-size: 1.125rem;
		.select-payments-row {
			display: flex;
			justify-content: space-between;
			align-items: center;
			border-top: 1px solid #cbcbcb;
			padding-top: 1rem;
			padding-bottom: 1rem;
			&.disabled {
				opacity: 0.5;
			}
			&:first-child {
				border-top: 0;
			}
			.amount {
				font-size: 1.5rem;
				font-weight: bold;
				display: flex;
				align-items: center;
				input[type="checkbox"] {
					transform: scale(1.5);
					margin-left: 1rem;
				}
			}
		}
	}

	.total-outstanding {
		background-color: var(--color-primary-tint);
		display: flex;
		align-items: center;
		justify-content: space-between;
		padding: 1rem;
		font-size: 1.125rem;
		.total-amount {
			font-size: 1.5rem;
			font-weight: bold;
		}
	}
	.action-buttons {
		padding-top: 2rem;
		padding-bottom: 2rem;
		.form-btn.btn {
			width: 100%;
		}
		@media screen and (min-width: 992px) {
			display: flex;
			justify-content: space-between;
			.form-btn.btn {
				width: 352px;
			}
		}
	}
}
