
.page-contact-details {
	
	dl {
		display: flex;
		flex-flow: row wrap;
	}

	dt {
		flex-basis: 20%;
		padding: 0.75rem 1rem;
		background-color: var(--color-table-1);
		text-align: left;
		color: #fff;
		border-bottom: 2px solid white;
		border-right: 2px solid white;
	}

	dd {
		flex-basis: 50%;
		flex-grow: 1;
		margin: 0;
		padding: 0.75rem 1rem;
		color: var(--color-text);
		background-color: var(--color-table-3);
		border-bottom: 2px solid white;
		&.truncate {
			white-space: nowrap;
			overflow: hidden;
			text-overflow: ellipsis;
		}
	}

	@media only screen and (min-width: 767.999px) {
		dt {
			flex-basis: 25%;
		}

		dd {
			flex-basis: 60%;
		}
	}

	dt.pad, dd.pad {
		margin-top: 0.5rem;
		margin-bottom: 0.5rem;
	}

	dd.email {
		white-space: nowrap;
		overflow-x: auto;
		scrollbar-width: thin;
	}

	dd {
		.contact-name-display{
				display:flex;
				justify-content: space-between;
				align-items: center;
		}
	}

	.header-container {
		display:flex;
		justify-content: space-between;
		align-items: center;

		h2 {
			margin:0 0.5rem 0 0;
		}

		.edit-button {
			min-width: 100px;
			text-align: right;
			&:hover {
				color: var(--color-link-button-hover);
				svg {
					.svg-icon-fill {
							fill: var(--color-link-button-hover);
					}
				}
				a {
					color: var(--color-link-button-hover);
				}
			}

			svg {
				width: 1rem;
				height: 1rem;
				margin-right: 8px;

				.svg-icon-fill{
					fill: var(--color-link-button);
					transition: all .25s ease-in-out;
				}
			}

			a {
				color: var(--color-link-button);
				transition: all .25s ease-in-out;
			}
		}
	}
}
