
.form {

	&.form-contact-details {
		.field { 
			margin-bottom: 0.875rem;
		}

		select{
			font-size: 1.0625rem;
		}
	}
	.field {
		width: 90%;
		label { 
			display: block;
		}
		padding-bottom: 1rem;
		input:not([type=checkbox]) {
			border: 1px solid grey;
			border-radius: 0.25rem;
			font-family: inherit;
			padding: 0.5rem;
			width: 100%;
			box-sizing: border-box;
			@media only screen and (min-width: 992px) {
				// max-width: 352px;
			}
		}
		@media only screen and (min-width: 992px) {
			width: 358px;
		}

		.invalid-message { 
			display: none;
		}
	
		&.invalid, &.valid {
			position: relative;

			&::after {
				content: "";
				background-repeat: no-repeat;
				background-position: 50%;
				background-size: 9px 9px;
				width: 9px;
				height: 9px;
				min-width: 9px;
				min-height: 9px;
				padding: 4px;
				border-radius: 50%;
				display :block;
				position :absolute;
				right: -1.5rem;
				@media screen and (min-width: 992px) {
					right: -1.5rem;
				}
			}
		}

		&.invalid {
			input {
				border: 1px solid var(--color-error) !important;
			}

			.invalid-message {	
				display: block;
				color: var(--color-error);
				font-size: 0.875rem;
				margin-top: 0.5rem;
			}

			&::after {
				background-image: url('../static/exclamation-solid.svg');
				background-color: var(--color-error);
				top: 2.25rem;
			}
		}

		&.valid {
			position: relative;

			input {
				border: 1px solid var(--color-valid) !important;
			}

			&::after {
				background-image: url('../static/check-solid.svg');
				background-color: var(--color-valid);
				top: 2.25rem;
			}
		}
	}
	
	input[type=checkbox] {
		accent-color: var(--color-primary);
	}
	
	select {
		background-color: white;
		border: 1px solid grey;
		border-radius: 0.25rem;
		font-family: inherit;
		padding: 0.5rem;
	}
}

