
.page-update-payment {
	.spinner {
		margin-bottom: 1.875rem;
	}
}

.payment-method-switch {
	width: fit-content;
	display: flex;
	margin-bottom: 2rem;

	div {
		cursor: pointer;
			padding: 0.75rem;
			background-color: var(--color-light-grey);

			&:first-child {
				border-top: 1px solid var(--color-grey);
				border-left: 1px solid var(--color-grey);
				border-bottom: 1px solid var(--color-grey);
				border-radius: 4px 0px 0px 4px;
			}

			&:nth-child(2) {
					border-top: 1px solid var(--color-grey);
					border-right: 1px solid var(--color-grey);
					border-bottom: 1px solid var(--color-grey);
					border-radius: 0px 4px 4px 0px;
			}

			&.active {
					background-color: var(--color-primary);
					border: 1px solid var(--color-primary);
					color: #FFFFFF;
			}
	}
}

.payment-detail-form {
	margin-bottom: 3rem;
	label {
			display: block;
			padding-bottom: 0.5rem;
			font-size: 1.0625rem;
	}
	
	input, select {
			// margin-bottom: 2rem;
			font-family: inherit;
			font-size: 1.0625rem;
			width: 100%;
			padding: 1rem;
			border-radius: 4px;
			border: 1px solid var(--color-grey);
			box-sizing: border-box;

			@media screen and (min-width: 992px) {
					width: 60%;
			}
			
	}

	.btn-container {
		display:flex;
		gap: 1rem;
		flex-wrap: wrap;
		width:100%;

		.form-btn{
			width:100%;
		}

		@media screen and (min-width: 992px) {
			width: 71%;

			.form-btn {
				width: calc(50% - 0.5rem);
			} 
		}
			
	}

	div.invalid {
		& > input {
			border: 1px solid var(--color-error);
		}

		label{
			color: var(--color-error);
		}
	}

	&.credit-card-form {
		#div_ac_expirydate_value {
			display:flex;
			width: 100%;
			gap: 1rem;

			input{ 
				text-align: center;
				&#cc_expiry_month {
						width: 4rem;
				}

				&#cc_expiry_year {
						width: 4rem;
				}
			}
		}

		.cvv-container {
				width:5rem;

				.cvv-label-container {
						display:flex;
						justify-content: space-between;
				}

				.cvv {
						width: 5rem;
						text-align: center;
				}
		}
	}
	

	&.direct-debit-form {
			.ng-autocomplete {
				width: 100%;
				display:block;
				height: 54px;
				&.errorBox {
					.autocomplete-container {
						margin-bottom: 1.3rem;
					}				
				}
				.autocomplete-container {
					width: 100%;
					@media screen and (min-width: 992px) {
						width: 60%;
					}
					.input-container { 
						input {
							font-family: inherit;
							font-size: 1.0625rem;
							width: 100%;
							padding: 1rem;
							border-radius: 4px;
							border: 1px solid var(--color-grey);
							box-sizing: border-box;
							display:block;
							height:100%;						
						}

						.x {
							top:40%;
							transform: none;
						}
					}
					
				}
			}
	}

	.confirmation-container {
			display: flex;
			gap: 1rem;
			margin-top: 1rem;
			margin-bottom: 2rem;
			align-items: flex-start;
			input {
					min-width:24px;
					min-height: 24px;
					width:24px;
					accent-color: var(--color-primary);
			}

			@media screen and (min-width:992px) {
					width: 60%;
			}
	}

	.invalid-message {
			background-color: var(--color-error-tint);
			padding:1em;
			line-height: 1.5em;
			margin-bottom: 3em;
			font-size: 1.0625rem;
	}

	.errorText, #div_ac_ddbsbbank_root {
		margin-top: 0.5rem;
	}

	#div_ac_ddbsb_root, 
	#div_ac_cardname_root, 
	#div_ac_expirydate_root, 
	#div_ac_ddnumber_root,
	#div_ac_ccnumber_root,
	#div_ac_ddtran_root,
	#div_ac_ddname_root {
		margin-top: 1.5rem;
	}

	input.ng-invalid {
		scroll-margin-top: 6rem;
		@media screen and (min-width: 992px) {
			scroll-margin-top: 3rem;
		}
	}
}
