.flash-message {
	padding: 1.25rem;
    font-size: 1.125rem;
    margin-bottom: 1rem;
	background-color: var(--color-flash-message-green);
	color: var(--color-flash-message-green-text);

    &.error {
      background-color: var(--color-flash-message-red);
    	color: var(--color-flash-message-red-text);
    }

    a{
        @media screen and (min-width: 992px){
            &[href*="tel:"]{
                color: #000000;
            }
        }
    }


}

.flash-message ~ .edit-button {
	margin-top: 1rem;
}
