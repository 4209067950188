.page-dashboard{
	.policy-summary {
		padding: 1rem;
		border: 1px solid #CBCBCB;
		line-height: 1.8;
		margin-bottom: 1rem;
	
		&:last-child{
			margin-bottom: 0;
		}
	
		.btn-link{
			text-decoration: none;
		}
	
		.header{
			display:flex;
			justify-content: space-between;
			padding-bottom: 0.75rem;

			.policy-title{
				display: flex;
				align-items: center;
				
				& > a {
					text-decoration: none;
				}

				h2 {
					margin: 0 0 0 1rem;
					color: var(--color-primary);
					font-size: 1.5rem;
					line-height: 28px;
					transition: color .25s ease-in-out;
					&:hover{
						color: var(--color-primary-hover);
					}					
				}
				
			}
			
			button{
				display:none;
				@media screen and (min-width: 992px) {
					display: block;
				}
			}
	
			@media screen and (min-width: 992px) {
				border-bottom: 1px solid #CBCBCB;
				margin-bottom: 0.75rem;
			}
		}
	
		table{
			width:100%;
			td{
				font-size: 1rem;
				vertical-align: top;
			}
			td:first-child {
				width:50%;
				margin-right: 1rem;
			}
	
			td:nth-child(2){
				font-weight: bold;
			}
			@media screen and (min-width: 992px) {
				width: 70%;
			}
		}
	
		.mobile-actionbar{
			border-top: 1px solid #CBCBCB;
			padding-top: 0.75rem;
			margin-top: 0.75rem;
	
			button{
				display: flex;
				width:100%;
				justify-content: center;
			}
			@media screen and (min-width: 992px) {
				display:none;
			}
		}
	
		@media screen and (min-width: 992px) {
			padding-left: 2rem;
			padding-right: 2rem;
		}
		
	}
}
