.form.form-contact-details{
	font-size: 1.0625rem;
		
	label{
		color: #000000;
	}

	input[type=checkbox]{
		min-width:16px;
		min-height: 16px;
		width: 16px;
	}
	.field.field-contact-address-lookup, .field.field-contact-postal-address-container {
		padding-bottom: 0;
		width: 90%;
		.form-contact-address-lookup, .form-contact-postal-address-lookup {
			input{
				width: 100% !important;
				@media screen and (min-width: 992px) {
					width: 506px !important;
				}
				padding: 0.5rem 40px 0.5rem 0.5rem;
				background: url('../../static/search-transp.svg');
				background-repeat: no-repeat;
				background-size: 24px;
				background-position: 98% 50%;
				

				&:focus{
					background: url('../../static/search-opaque.svg');
					background-repeat: no-repeat;
					background-size: 24px;
					background-position: 98% 50%;
				}
			}

			.address-matches {
				width: 100% !important;
				background: #FFFFFF;
				border-radius: 0 0 15px 15px;
				//border: 1px solid #333;
				box-shadow: 0px 0px 5px rgba(0,0,0,0.3);
				margin-left:1px;

				@media screen and (min-width: 992px) {
					width: 506px !important;
				}

				p{
					border-bottom: 1px solid #333;
					padding: 0.5rem;
					margin:0;
					font-size: 1rem;

					&:last-child{
						border: none;
					}

					&:hover{
						background: #efefef;
					}
				}
			}
			
		}
	}

	.field.field-contact-address-lookup.invalid:after, 
	.field.field-contact-address-lookup.valid:after, 
	.field.field-contact-postal-address-lookup.invalid:after, 
	.field.field-contact-postal-address-lookup.valid:after {
		background: none;
		background-color: none;
	}
		
	.address-mode-button {
		cursor: pointer;
		transition: color 0.25s ease-in-out;
		color: var(--color-link-button);
		&:hover {
			color: var(--color-link-button-hover);
		}
	}
}
