.overdue-banner {
	background-color: #e6e6e6;
	padding-top: 2rem;
	padding-bottom: 1rem;

	.banner {
		min-height: 4rem;
		background-color: #FFFFFF;
		padding-top: 1rem;
		padding-left: 2rem;
		padding-right: 2rem;
		margin-bottom: 1rem;
		display: flex;
		flex-wrap: wrap;
		justify-content: space-between;
		align-items: center;
		box-shadow: 0px 3px 6px #00000029;

		.message {
			font-size: 1.125rem;
			margin-bottom: 1rem;

			.notification-bell {
				margin-right: 1rem;
			}
			
			.message-content {
				line-height: 24px;
				height:24px;
				margin-right: 12px;
			}
		}
       
		.btn {
			border: none;
			margin-bottom: 1rem;
			padding: 12px 42px;
		}
	}

	@media screen and (min-width: 860px){
		.banner {
			.btn {
				margin-left: auto;
			}
		}
	}
}


