
.spinner {
	svg{
		width: 100px;
		height: 100px;
		circle{
			fill: var(--color-primary);
		}
	}
}
