.modal-link{
    text-decoration: underline;
    background: none;
    border: none;
    font-size: 1rem;
    &:hover{
        color: lighten(#000000, 10%);
        cursor: pointer;
    }
}

.modal-overlay{
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left:0;
    background-color: rgba(0, 0, 0, 0.3);
    z-index:3;
    justify-content: center;
    align-items: center;

    .modal-window{
        position: relative;
        background-color: #FFFFFF;
        width: 40%;
        padding: 2rem;
        
        h2{
            margin-top: 0;
            padding-bottom: 1rem;
            border-bottom: 1px #333333 solid;
        }

        p:last-of-type{
            margin-bottom: 4.5rem;
        }

        .btn-primary{
            padding: 1rem 2rem;
            border: none;
            color: #FFFFFF;
            position: absolute;
            bottom: 2rem;
            right: 2rem;

        }

        @media screen and (max-width: 992px) {
            width: auto;
            margin-left: 1rem;
            margin-right: 1rem;
            padding: 2rem 1rem;

            
        }
    }
}