.spinner-overlay {
	width: 100%;
	height: 100vh;
	background-color: var(--color-modal-backdrop);
	position: fixed;
	top: 0;
	left: 0;
	display: flex;
	justify-content: center;
	align-items: center;
	z-index: 2;

	.loading-modal {
		background-color: #FFFFFF;
		padding: 2rem;
		text-align: center;

		h2 {
			color: var(--color-primary);
			font-size: 2rem;
		}

		p {
			color: var(--color-grey);
			font-size: 1.25rem;
		}
	}
}
